<template>
  <div class="login-page">
    <img src="../img/loading.gif" class="loading-gif" alt="Loading..." />
  </div>
</template>

<script>
import api from '@/service/api'
import http from '@/service/axios'
import { setCookie } from '@/common/cookie'
import { mapMutations } from 'vuex'
import { singleSignOnURL } from '@/config/config'

export default {
  mounted() {
    const query = this.$route.query
    if (query.code) {
      //单点登录回调
      localStorage.setItem('apiURL', query.gateway_uri)
      setTimeout(() => {
        this.oauthLogin(query.code)
      })
      return
    } else {
      window.location.href = singleSignOnURL
    }
  },
  data() {
    return {
      menus: [],
    }
  },
  methods: {
    ...mapMutations(['setState']),
    menusJump() {
      let view = ''
      if (this.menus.length == 0) {
        this.menus = this.$store.state.menuList
      }
      let pageCacheFullPath = localStorage.getItem('pageCacheFullPath')
      if (pageCacheFullPath) {
        console.log('pageCacheFullPath: ', pageCacheFullPath)
        localStorage.removeItem('pageCacheFullPath')
        this.$router.push(pageCacheFullPath)
        return
      } else {
        view = this.menus?.[0]?.children[0].view
        this.$router.push({
          name: view,
        })
      }
    },
    oauthLogin(code) {
      http({
        url: api.authorizeOauth,
        type: 'post',
        data: {
          authCode: code,
        },
        success: (res) => {
          setCookie({ d_session_id: res.result })
          this.getSession(res.result)
        },
        fail: (res) => {
          if (res.message === 'authCode is invalid!') {
            window.location.href = singleSignOnURL
          }
        },
      })
    },
    getSession() {
      http({
        url: api.getSession,
        type: 'post',
        data: {
          appCode: 'FMS',
        },
        success: (res) => {
          this.setUserInfo(res.result)
        },
      })
    },
    setUserInfo(userInfo) {
      setCookie({ reportPlatform: 'fms' })
      this.menus = userInfo.menuList
      this.setState({
        menuList: userInfo.menuList,
      })
      userInfo.currentBu = userInfo.buList?.[0] || {}
      const settlementBuList = userInfo.settlementBuList
      if (settlementBuList?.length === 0) {
        this.$warning(this.$t('结算组织为空，请找管理员授权结算组织'))
      }
      this.getBuList()
      this.setState({
        userInfo,
        userSession: userInfo,
      })
      let merchantCode = userInfo.merchantCode
      localStorage.setItem('userAccount', userInfo.currentUser)
      localStorage.setItem('merchantName', userInfo.merchantName)
      localStorage.setItem('merchantCode', merchantCode)
      localStorage.setItem('originalMerchantCode', userInfo.originalMerchantCode)

      this.$nextTick(() => {
        this.menusJump()
      })
    },
    getBuList() {
      http({
        url: api.getBusinessUnitList,
        success: (res) => {
          let list = res?.result?.list || []
          this.setState({
            settlementBuList: list,
          })
          let defaultBu = localStorage.getItem('defaultBu')
          if (list.length > 0 && !defaultBu) {
            this.setState({
              defaultBu: list[0],
            })
          }
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.login-page {
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .loading-gif {
    width: 100px;
  }
}
</style>
